import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { reduxForm } from "redux-form";

//imported
import { loginUser } from "store/actions";
import * as Path from "routes/Path/index";

import { FORGOT_PASSWORD, LOGIN } from "common/viewContent";
import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";
import InputFields from "./inputFields";
import { toast } from "react-toastify";
import { getCookie } from "helpers/common";

const Login = ({ submitting }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.Auth);

  //values
  const initialValues = {
    email: "",
    password: "",
    twoFACode: "",
  };

  const handleSubmit_ = async (values) => {
    try {
      const callBack = (err, response) => {

        if (response?.status === "success" && response?.status_code === 200) {
          setTimeout(() => {
            if (response?.data?.role === "ADMIN") {
              navigate("/admin/" + Path.users);
              return;
            }
            if (response?.data?.role === "SUBADMIN") {
              navigate("/admin/" + Path.profile);
              return;
            }
          }, 1500);
        } else {
          // toast.error(err.message);
        }
      };
      console.log("values", values)
      // values = JSON.stringify(values);
      await dispatch(loginUser({ data: values, callBack }));
    } catch (err) {
      console.log(err, "<====err");
      toast.error(err.message);
    }
  };

  return (
    <>
      <Helmet title={LOGIN.CURRENT_MODULE} />
      <div className="container mt-5" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="login-brand">
              <p>{LOGIN.TOP_CONTENT}</p>
            </div>
            <div className="card card-primary">
              <div className="card-header">
                <h4>{LOGIN.MAIN_CONTENT}</h4>
              </div>

              <div className="card-body">
                <FormikForm
                  initialValues={initialValues}
                  validationSchema={Validation}
                  onSubmit={handleSubmit_}
                  loading={loading}
                  inputFields={InputFields}
                  submitting={submitting}
                  buttonText={LOGIN.BUTTON}
                  is_block={true}
                  col={12}
                  is_passwordHide={false}
                />

                {/* <div className="float-right">
                  <Link to={Path.forgot_password} className="text-small">
                    {FORGOT_PASSWORD.BUTTON}?
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="simple-footer">
              Copyright &copy; {process.env.REACT_APP_FOOTER_COPYRIGHT_YEAR}{" "}
              {process.env.REACT_APP_APPNAME_FOOTER}
              {". "}
              <br></br>
              {process.env.REACT_APP_DESIGN_FOOTER}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LoginForm = reduxForm({
  form: "login",
})(Login);

export default LoginForm;
