import React from "react"
//imported
import * as Path from "../Path"

//dashboard
import Dashboard from "views/dashboard/Dashboard"

//User
import UserList from "views/users/List"
import Login from "views/auth/Login/Login"
import ForgetPassword from "views/auth/Forget-password/ForgotPassword"
import GeneralSettings from "views/settings/General/index"
import Profile from "views/auth/profile/index"
import ChangePassword from "views/auth/change_password/index"
import MGameSettings from "views/settings/Mini-Game";
import EnerygySetting from "views/settings/Energy";
import LeaderboardSettings from "views/settings/Leaderboard";
import MaintenanceSettings from "views/settings/Maintenance"
import LanguageList from "views/language/List";

//game
import GameHistoryList from "views/gameHistory/List"
import GameHistoryView from "views/gameHistory/View"
import GameLeaderboard from "views/gameLeaderboard/List"
import ClaimHistory from "views/claimHistory/List"
import UserView from "views/users/View"
import TasksListPage from "views/tasks/List"
import RoadmapList from "views/roadmap/List"
import Statistics from "views/Statistics"
import Logs from "views/Logs/List"
import SubAdminsList from "views/subadmins/List"
import SubAdminView from "views/subadmins/View"



const routes = [
  {
    path: Path.statistics,
    exact: true,
    auth: false,
    component: <Statistics />,
    permission: "statistics"
  },
  {
    path: Path.dashboard,
    exact: true,
    auth: false,
    component: <Dashboard />,
    permission: "dashboard"
  },
  {
    path: Path.users,
    exact: true,
    auth: true,
    component: <UserList />,
    permission: "manage_users"
  },
  {
    path: Path.usersView,
    exact: true,
    auth: true,
    component: <UserView />,
    permission: "manage_users"
  },
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    component: <Login />,
  },
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: <ForgetPassword />,
  },
  {
    path: Path.changePassword,
    exact: true,
    auth: false,
    component: <ChangePassword />,
  },
  {
    path: Path.game_setting,
    exact: true,
    auth: false,
    component: <MGameSettings />,
    permission: "configurations"
  },
  {
    path: Path.energy_setting,
    exact: true,
    auth: false,
    component: <EnerygySetting />,
    permission: "configurations"
  },
  {
    path: Path.leaderboard_setting,
    exact: true,
    auth: false,
    component: <LeaderboardSettings />,
    permission: "configurations"
  },
  {
    path: Path.maintenance_setting,
    exact: true,
    auth: false,
    component: <MaintenanceSettings />,
    permission: "configurations"
  },
  // {
  //   path: Path.general_setting,
  //   exact: true,
  //   auth: false,
  //   component: <GeneralSettings />,
  // },

  {
    path: Path.profile,
    exact: true,
    auth: false,
    component: <Profile />,
    permission: "profile"
  },

  {
    path: Path.language,
    exact: true,
    auth: false,
    component: <LanguageList />,
    permission: "languages"
  },
  {
    path: Path.gameHisory,
    exact: true,
    auth: false,
    component: <GameHistoryList />,
    permission: "game_history"
  }, {
    path: Path.gameHisoryView,
    exact: true,
    auth: false,
    component: <GameHistoryView />,
    permission: "game_history"
  }, {
    path: Path.gameLeaderboard,
    exact: true,
    auth: false,
    component: <GameLeaderboard />,
    permission: "leaderboard"
  }, {
    path: Path.claimHistory,
    exact: true,
    auth: false,
    component: <ClaimHistory />,
    permission: "claim_history"
  },
  {
    path: Path.tasksList,
    exact: true,
    auth: false,
    component: <TasksListPage />,
    permission: "tasks"
  },
  {
    path: Path.roadmapsList,
    exact: true,
    auth: false,
    component: <RoadmapList />,
    permission: "roadmap"
  },
  {
    path: Path.logs,
    exact: true,
    auth: true,
    component: <Logs />,
    permission: "logs"
  },
  {
    path: Path.subadmins,
    exact: true,
    auth: true,
    component: <SubAdminsList />,
    permission: "subadmins"
  },
  {
    path: Path.subAdminView,
    exact: true,
    auth: true,
    component: <SubAdminView />,
    permission: "subadmins"
  },
]

export default routes