import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { getCoinsListApi, getSettings, updateGameSettings, uploadFile } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./Instructions";
import Select from 'react-select';
import { Alert, Button } from "react-bootstrap";


const cryptoList = [
  {
    "name": "Bitcoin",
    "icon": "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
    "symbol": "BTC"
  },
  {
    "name": "Ethereum",
    "icon": "https://cryptologos.cc/logos/ethereum-eth-logo.png",
    "symbol": "ETH"
  },
  {
    "name": "Tether",
    "icon": "https://cryptologos.cc/logos/tether-usdt-logo.png",
    "symbol": "USDT"
  },
  {
    "name": "Binance Coin",
    "icon": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
    "symbol": "BNB"
  },
  {
    "name": "USD Coin",
    "icon": "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
    "symbol": "USDC"
  },
  {
    "name": "Ripple",
    "icon": "https://cryptologos.cc/logos/ripple-xrp-logo.png",
    "symbol": "XRP"
  },
  {
    "name": "Cardano",
    "icon": "https://cryptologos.cc/logos/cardano-ada-logo.png",
    "symbol": "ADA"
  },
  {
    "name": "Dogecoin",
    "icon": "https://cryptologos.cc/logos/dogecoin-doge-logo.png",
    "symbol": "DOGE"
  },
  {
    "name": "Polygon",
    "icon": "https://cryptologos.cc/logos/polygon-matic-logo.png",
    "symbol": "MATIC"
  },
  {
    "name": "Solana",
    "icon": "https://cryptologos.cc/logos/solana-sol-logo.png",
    "symbol": "SOL"
  }
]
const MGameSettings = () => {
  // const  t =useTransition();
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(({ Settings }) => Settings);
  const { coinsList, loadingCoins } = useSelector(({ Game }) => Game);

  const [options, setOptions] = useState();

  useEffect(() => {
    dispatch(getCoinsListApi({}));
  }, []);

  useEffect(() => {
    let data = [];
    if (coinsList?.length) {
      data = (coinsList).map((item) => ({ label: item.name, value: item.name }));

    }
    //  else {
    //   data = (cryptoList).map((item) => ({ label: item.name, value: item.name }));
    // }
    setOptions(data);
  }, [coinsList]);

  const findDefaultOption = (value) => {
    try {
      return options.find(option => option.value === value);

    } catch {
      return null;
    }
  };

  const [fields, setFields] = useState(
    {
      "game": {
        "minLine1": 1,
        "maxLine1": 10,
        "bombCountLine1": 1,
        "coinTotalLine1": 10,
        "coinValueLine1": 1,
        "coinNameLine1": "",
        "coinSymbolLine1": "",
        "coinIconLine1": "",
        "coinStatusLine1": "disabled",

        "minLine2": 11,
        "maxLine2": 20,
        "bombCountLine2": 1,
        "coinTotalLine2": 10,
        "coinValueLine2": 1,
        "coinNameLine2": "",
        "coinSymbolLine2": "",
        "coinIconLine2": "",
        "coinStatusLine2": "disabled",

        "minLine3": 21,
        "maxLine3": 30,
        "bombCountLine3": 1,
        "coinTotalLine3": 10,
        "coinValueLine3": 1,
        "coinNameLine3": "",
        "coinSymbolLine3": "",
        "coinIconLine3": "",
        "coinStatusLine3": "disabled",

        "minLine4": 31,
        "maxLine4": 40,
        "bombCountLine4": 1,
        "coinTotalLine4": 10,
        "coinValueLine4": 1,
        "coinNameLine4": "",
        "coinSymbolLine4": "",
        "coinIconLine4": "",
        "coinStatusLine4": "disabled",

        "minLine5": 41,
        "maxLine5": 50,
        "bombCountLine5": 1,
        "coinTotalLine5": 10,
        "coinValueLine5": 1,
        "coinNameLine5": "",
        "coinSymbolLine5": "",
        "coinIconLine5": "",
        "coinStatusLine5": "disabled",

        "minLine6": 51,
        "maxLine6": 60,
        "bombCountLine6": 2,
        "coinTotalLine6": 10,
        "coinValueLine6": 1,
        "coinNameLine6": "",
        "coinSymbolLine6": "",
        "coinIconLine6": "",
        "coinStatusLine6": "disabled",

        "minLine7": 61,
        "maxLine7": 70,
        "bombCountLine7": 2,
        "coinTotalLine7": 10,
        "coinValueLine7": 1,
        "coinNameLine7": "",
        "coinSymbolLine7": "",
        "coinIconLine7": "",
        "coinStatusLine7": "disabled",

        "minLine8": 71,
        "maxLine8": 80,
        "bombCountLine8": 2,
        "coinTotalLine8": 10,
        "coinValueLine8": 1,
        "coinNameLine8": "",
        "coinSymbolLine8": "",
        "coinIconLine8": "",
        "coinStatusLine8": "disabled",

        "minLine9": 81,
        "maxLine9": 90,
        "bombCountLine9": 3,
        "coinTotalLine9": 10,
        "coinValueLine9": 1,
        "coinNameLine9": "",
        "coinSymbolLine9": "",
        "coinIconLine9": "",
        "coinStatusLine9": "disabled",

        "minLine10": 91,
        "maxLine10": 100,
        "bombCountLine10": 3,
        "coinTotalLine10": 10,
        "coinValueLine10": 1,
        "coinNameLine10": "",
        "coinSymbolLine10": "",
        "coinIconLine10": "",
        "coinStatusLine10": "disabled"
      }

    }
  );


  useEffect(() => {
    dispatch(getSettings());
  }, [])

  useEffect(() => {
    if (settings?._id) {
      console.log("settings", settings)
      setFields((prevState) => ({
        ...prevState,
        game: settings?.game,
        leaderboard: settings?.leaderboard,
        energy: settings?.energy
      }));
    }
  }, [JSON.stringify(settings)]);

  const bombLines = ["bombCountLine1", "bombCountLine2", "bombCountLine3", "bombCountLine4", "bombCountLine5", "bombCountLine6", "bombCountLine7", "bombCountLine8", "bombCountLine9", "bombCountLine10"]
  const handleChange = (key) => (event) => {
    console.log("event", event)
    const { name, value } = event?.target;
    if (bombLines.includes(name) && parseInt(value) > 3) {
      return;
    }
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: Number(value) }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };

  function submit() {
    const findCoinSymbolLine1 = coinsList.find((item) => item.name === fields?.game?.coinNameLine1)?.symbol;
    const findCoinIconLine1 = coinsList.find((item) => item.name === fields?.game?.coinNameLine1)?.icon;
    const findCoinSymbolLine2 = coinsList.find((item) => item.name === fields?.game?.coinNameLine2)?.symbol;
    const findCoinIconLine2 = coinsList.find((item) => item.name === fields?.game?.coinNameLine2)?.icon;
    const findCoinSymbolLine3 = coinsList.find((item) => item.name === fields?.game?.coinNameLine3)?.symbol;
    const findCoinIconLine3 = coinsList.find((item) => item.name === fields?.game?.coinNameLine3)?.icon;
    const findCoinSymbolLine4 = coinsList.find((item) => item.name === fields?.game?.coinNameLine4)?.symbol;
    const findCoinIconLine4 = coinsList.find((item) => item.name === fields?.game?.coinNameLine4)?.icon;
    const findCoinSymbolLine5 = coinsList.find((item) => item.name === fields?.game?.coinNameLine5)?.symbol;
    const findCoinIconLine5 = coinsList.find((item) => item.name === fields?.game?.coinNameLine5)?.icon;
    const findCoinSymbolLine6 = coinsList.find((item) => item.name === fields?.game?.coinNameLine6)?.symbol;
    const findCoinIconLine6 = coinsList.find((item) => item.name === fields?.game?.coinNameLine6)?.icon;
    const findCoinSymbolLine7 = coinsList.find((item) => item.name === fields?.game?.coinNameLine7)?.symbol;
    const findCoinIconLine7 = coinsList.find((item) => item.name === fields?.game?.coinNameLine7)?.icon;
    const findCoinSymbolLine8 = coinsList.find((item) => item.name === fields?.game?.coinNameLine8)?.symbol;
    const findCoinIconLine8 = coinsList.find((item) => item.name === fields?.game?.coinNameLine8)?.icon;
    const findCoinSymbolLine9 = coinsList.find((item) => item.name === fields?.game?.coinNameLine9)?.symbol;
    const findCoinIconLine9 = coinsList.find((item) => item.name === fields?.game?.coinNameLine9)?.icon;
    const findCoinSymbolLine10 = coinsList.find((item) => item.name === fields?.game?.coinNameLine10)?.symbol;
    const findCoinIconLine10 = coinsList.find((item) => item.name === fields?.game?.coinNameLine10)?.icon;


    dispatch(
      updateGameSettings({
        data: {
          ...fields.game,
          coinSymbolLine1: findCoinSymbolLine1,
          coinIconLine1: findCoinIconLine1,
          coinSymbolLine2: findCoinSymbolLine2,
          coinIconLine2: findCoinIconLine2,
          coinSymbolLine3: findCoinSymbolLine3,
          coinIconLine3: findCoinIconLine3,
          coinSymbolLine4: findCoinSymbolLine4,
          coinIconLine4: findCoinIconLine4,
          coinSymbolLine5: findCoinSymbolLine5,
          coinIconLine5: findCoinIconLine5,
          coinSymbolLine6: findCoinSymbolLine6,
          coinIconLine6: findCoinIconLine6,
          coinSymbolLine7: findCoinSymbolLine7,
          coinIconLine7: findCoinIconLine7,
          coinSymbolLine8: findCoinSymbolLine8,
          coinIconLine8: findCoinIconLine8,
          coinSymbolLine9: findCoinSymbolLine9,
          coinIconLine9: findCoinIconLine9,
          coinSymbolLine10: findCoinSymbolLine10,
          coinIconLine10: findCoinIconLine10,
        }
      }));
  }


  const handleChangeString = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: value }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };


  const handleSelectChange = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };

    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: value }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };
  // const handleUploadImage = (name) => (file) => {
  //   console.log("file", file, "name", name)
  //   if (!file) return;
  //   dispatch(uploadFile({
  //     data: file, callback: (err, response) => {
  //       if (err) return;
  //       if (response?.status === "success") {
  //         console.log("datadata", response)
  //         const url = response?.data?.url;
  //         setFields((pre) => ({ ...pre, game: { ...pre.game, [name]: url } }))
  //       }
  //     }
  //   }))
  // }

  // const handleChangeRemoveFile = (name) => {
  //   setFields((pre) => ({ ...pre, game: { ...pre.game, [name]: null } }))
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12} lg={12}>
              <Card>
                <CardBody className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {loading && <div className="spinner"></div>}

                  <Col lg={8}>
                    <Instructions />
                  </Col>

                  <Col lg={8}>
                    <Row>

                      {/* <Col md={6} /> */}
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine1}
                            name="minLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />



                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine1}
                            name="maxLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />
                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine1}
                            maxValue={fields?.minigameSettings?.maxLine1}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine1", "maxLine1")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine1}
                            name="bombCountLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine1}
                            name="coinTotalLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine1}
                            name="coinValueLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine1)}
                            name="coinNameLine1"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine1", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine1"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine1 === "enabled" ? true : false}
                            id="coinStatusLine1" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine1 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine1", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine1" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine2}
                            name="minLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine2}
                            name="maxLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine2}
                            name="bombCountLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine2}
                            name="coinTotalLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine2}
                            name="coinValueLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine2)}
                            name="coinNameLine2"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine2", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine2"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine2 === "enabled" ? true : false}
                            id="coinStatusLine2" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine2 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine2", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine2" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine3}
                            name="minLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine3}
                            name="maxLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine3}
                            maxValue={fields?.minigameSettings?.maxLine3}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine3", "maxLine3")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine3}
                            name="bombCountLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine3}
                            name="coinTotalLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine3}
                            name="coinValueLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine3)}
                            name="coinNameLine3"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine3", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine3"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine3 === "enabled" ? true : false}
                            id="coinStatusLine3" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine3 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine3", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine3" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine4}
                            name="minLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine4}
                            name="maxLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine4}
                            maxValue={fields?.minigameSettings?.maxLine4}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine4", "maxLine4")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine4}
                            name="bombCountLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine4}
                            name="coinTotalLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine4}
                            name="coinValueLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine4)}
                            name="coinNameLine4"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine4", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine4"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine4 === "enabled" ? true : false}
                            id="coinStatusLine4" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine4 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine4", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine4" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Range</Label>


                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine5"
                            value={fields?.game?.minLine5}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            name="maxLine5"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine5}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine5}
                            name="bombCountLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine5}
                            name="coinTotalLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine5}
                            name="coinValueLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine5)}
                            name="coinNameLine5"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine5", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine5"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine5 === "enabled" ? true : false}
                            id="coinStatusLine5" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine5 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine5", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine5" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine6}
                            name="minLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine6}
                            name="maxLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine6}
                            name="bombCountLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine6}
                            name="coinTotalLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine6}
                            name="coinValueLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine6)}
                            name="coinNameLine6"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine6", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine6"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine6 === "enabled" ? true : false}
                            id="coinStatusLine6" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine6 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine6", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine6" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine7}
                            name="minLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            name="maxLine7"
                            value={fields?.game?.maxLine7}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />


                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine7}
                            name="bombCountLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine7}
                            name="coinTotalLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine7}
                            name="coinValueLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine7)}
                            name="coinNameLine7"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine7", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine7"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine7 === "enabled" ? true : false}
                            id="coinStatusLine7" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine7 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine7", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine7" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>



                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine8}
                            name="minLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine8}
                            name="maxLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />
                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine8}
                            maxValue={fields?.minigameSettings?.maxLine8}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine8", "maxLine8")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine8}
                            name="bombCountLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine8}
                            name="coinTotalLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine8}
                            name="coinValueLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine8)}
                            name="coinNameLine8"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine8", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine8"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine8 === "enabled" ? true : false}
                            id="coinStatusLine8" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine8 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine8", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine8" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine9}
                            name="minLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine9}
                            name="maxLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />


                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine9}
                            name="bombCountLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine9}
                            name="coinTotalLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine9}
                            name="coinValueLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine9)}
                            name="coinNameLine9"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine9", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine9"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine9 === "enabled" ? true : false}
                            id="coinStatusLine9" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine9 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine9", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine9" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine10"
                            value={fields?.game?.minLine10}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine10}
                            name="maxLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine10}
                            name="bombCountLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine10}
                            name="coinTotalLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine10}
                            name="coinValueLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine10)}
                            name="coinNameLine10"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine10", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine10"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine10 === "enabled" ? true : false}
                            id="coinStatusLine10" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine10 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine10", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine10" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6} />

                      <Col className="d-flex justify-content-end">
                        {loading && <Spinner color="primary" className="mr-2" />}

                        <Button className="btn btn-primary" onClick={submit}>
                          Save Changes
                        </Button>
                      </Col>

                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default MGameSettings;