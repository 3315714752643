import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";

//imported
import * as Path from "routes/Path/index";
import AURoadmapsModal from "components/Modals/AURoadmaps";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import { AddFAQ, deleteRoadmap, editFAQ, getRoadmapsList } from "store/roadmaps/actions";


const List = () => {
  const dispatch = useDispatch();

  const { loading, roadmapsList, totalCount } = useSelector(({ Roadmaps }) => ({
    loading: Roadmaps?.loading,
    roadmapsList: Roadmaps?.roadmapsList,
    totalCount: Roadmaps?.totalCount
  }));

  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [formData, setFormData] = useState({
    languageId: "",
    question: "",
    answer: "",
  });
  const [addRoadmapPop, setAddRoadmapPop] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
  });

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ deleteHandler, handleEdit }));
  }, [filter]);


  async function getApiData() {
    try {
      dispatch(getRoadmapsList({ data: filter }));
    } catch (error) { }
  }

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this roadmap?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
          } else {
            getApiData(filter);
          }
        };

        dispatch(
          deleteRoadmap({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleEdit = (info) => {
    console.log("handle edit called------>", info);
    setRowData(info);
    setAddRoadmapPop(true);
  };

  return (
    <>
      {addRoadmapPop && <AURoadmapsModal rowData={rowData} show={addRoadmapPop} handleClose={() => {
        setRowData(null);
        setAddRoadmapPop(false)
      }} />}
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={"Roadmaps"} />

      <section className="section">
        <div className="section-header">
          <h1>{"Roadmaps"}</h1>

          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{"Roadmap"} </div>
          </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{"Roadmap"}</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddRoadmapPop(true)}
                  >
                    Add Roadmap
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!loading && roadmapsList?.length > 0 && (
            <Table
              data={roadmapsList}
              columns={columns}
              // setFilter={setFilter}
              total={totalCount}
              // filter={filter}
              toggleConfirmModal={deleteHandler}
            />
          )}
          {!loading && roadmapsList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Roadmap Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;