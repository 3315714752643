import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const STATUS = {
  "active": "Active",
  "inactive": "Inactive",
  "blocked": "Blocked",
  "deleted": "Deleted"
}

const Columns = ({ handleEditHandler }) => [
  {
    dataField: "Username",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.telegram_username ? row?.userDetails?.telegram_username : row?.userDetails?.first_name}</span>,
  },
  {
    dataField: "weekNo",
    text: "Week No",
    formatter: (_, row) => <span className="text-capitalize">{row?.weekNo}</span>,
  },
  {
    dataField: "rank",
    text: "Rank",
    formatter: (_, row) => <span className="text">{row?.rank}</span>,
  },
  {
    dataField: "points",
    text: "Points",
    formatter: (_, row) => <span className="text">{row?.points}</span>,
  },
  {
    dataField: "prizes",
    text: "Prizes",
    formatter: (_, row) => <span className="text-capitalize">{row?.prizes || ""}</span>,
  },
  {
    text: "prizeType",
    dataField: "prizeType",
    formatter: (_, row) => <span className="text-capitalize">{row?.prizeType || ""}</span>,
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => DATE_FORMAT(row?.createdAt),
  },
  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <button
        className="fas fa-pen"
        style={{
          fontSize: "16px",
          backgroundColor: "#fff",
          border: "none",
        }}
        title="Edit"
        onClick={() =>
          handleEditHandler(row)
        }
      ></button>
    ),
  },
];
export default Columns;
