import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Path from "routes/Path/index";
import { gameEndApi, getSettings, getUserDetails, updateUserBalance, updateUserStatus } from "store/actions";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import EnergyHitory from "./EnergyHistory/List";
import ClaimHistory from "./ClaimHistory/List";
import GameHistory from "./GameHistory/List";
import Logs from "./Logs/List";
import { toast } from "react-toastify";



const UserView = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { userDetails, loadingUpBalance } = useSelector(({ Users }) => Users);
    const { settings, loading, error } = useSelector(({ Settings }) => Settings);

    const [activeTab, setActiveTab] = useState("energyHistory");
    const [balance, setBalance] = useState({
        btncBalance: 0,
        energyBalance: 0,
        adminNote: ""
    })

    console.log("userDetails", userDetails)
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id]);


    const fetchData = (id) => {
        dispatch(getUserDetails({
            id, callBack: (err, res) => {
                if (err) return;
                if (res?._id) {
                    setBalance(pre => ({
                        ...pre,
                        energyBalance: res?.energyBalance,
                        btncBalance: res?.btncBalance,
                        adminNote: res?.adminNote
                    }))
                }
            }
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (loading) return;
        if (name === "energyBalance" && value > settings?.energy?.maxEnergyBalance) {
            return;
        }
        console.log("[name]", name, "value", value);
        setBalance((pre) => ({ ...pre, [name]: value }));
    }
    const handleUserStatus = (id, status) => {
        try {
            const callback = (err, res) => {
                if (err) return;
                if (id) {
                    fetchData(id);
                }

            };

            dispatch(
                updateUserStatus({
                    data: { _id: id, status: status },
                    callback,
                })
            );
        } catch (error) {
            console.log(error, "<===err");
        }
    };
    console.log("balance", balance)

    const onUpdateInfo = () => {
        console.log("balance", balance)
        dispatch(updateUserBalance({
            data: { _id: userDetails?._id, ...balance }, callBack: (err, res) => {
                if (err) {
                    toast.error(err || "Something went wrong!");
                }
                toast.success(res?.message);
            }
        }))
    }

    const onEndGame = () => {
        if (!userDetails?.currentGame) return;
        dispatch(gameEndApi({
            data: { _id: userDetails?.currentGame }, callBack: (err, res) => {
                if (err) return;
                if (id) {
                    fetchData(id);
                }
            }
        }))

    }

    return (
        <>
            <section className="section">
                <div className="section-header">
                    <h1>User Details</h1>

                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item active">
                            <Link to={"/admin/" + Path.users}>Users</Link>
                        </div>
                        <div className="breadcrumb-item">User Details</div>
                    </div>
                </div>
                <div className="section-body">
                    <Row>
                        <Col sm={12} lg={6}>
                            <div className="py-2">
                                <div className="card">
                                    <div className="row justify-content-between p-4">
                                        <p><b>First name</b>: <span>{userDetails?.first_name}</span></p>
                                        <p><b>Last name</b>: <span>{userDetails?.last_name}</span></p>
                                        <p><b>telegram username</b>: <span>{userDetails?.telegram_username}</span></p>
                                        <p><b>user Id</b>: <span>{userDetails?.userID}</span></p>
                                        <p><b>username</b>: <span>{userDetails?.username}</span></p>
                                        {/* <p><b>Current Game</b>: <span>{userDetails?.currentGame}</span><span className="mx-2">
                                            <button
                                                className="btn btn-warning w-25"
                                                title="Active"
                                                onClick={() => onEndGame()}
                                            >Game End</button>
                                        </span>
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className="py-2">
                                <div className="card">
                                    <div className="row justify-content-between p-4">
                                        <p><b>Energy balance</b>:
                                            <br /><input name="energyBalance" className="my-1" style={{ width: 120, height: 40 }} type="text" value={balance.energyBalance} onChange={(e) => handleChange(e)} />
                                        </p>
                                        <p><b>BTNC balance</b>: <span> <br /><input className="my-1" style={{ width: 120, height: 40 }} name="btncBalance" type="text" value={balance.btncBalance} onChange={(e) => handleChange(e)} /></span></p>
                                        <p><b>Note</b>: <span> <br /><textarea className="my-1" style={{ width: 300, minHeight: 120 }} name="adminNote" type="text" value={balance.adminNote} onChange={(e) => handleChange(e)} /></span></p>

                                        <p><b>Status</b>: <span> {userDetails?.status && (userDetails?.status === "active") ? (
                                            <button
                                                className="fas fa-toggle-on"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "green",
                                                    backgroundColor: "#fff",
                                                    border: "none",
                                                }}
                                                title="Active"
                                                onClick={() => handleUserStatus(userDetails._id, "blocked")}
                                            ></button>
                                        ) : (
                                            <button
                                                className="fas fa-toggle-off"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "red",
                                                    backgroundColor: "#fff",
                                                    border: "none",
                                                }}
                                                title="Blocked"
                                                onClick={() => handleUserStatus(userDetails._id, "active")}
                                            ></button>
                                        )}</span> <span>{userDetails?.status}</span></p>

                                        <button
                                            className="btn btn-primary w-25"
                                            title="Active"
                                            disabled={loadingUpBalance}
                                            onClick={() => onUpdateInfo()}
                                        >Update</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="py-2">
                        <div className="card p-3">
                            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} activeKey={activeTab}>

                                <Nav variant="pills" className="gap-10">
                                    <Nav.Item onClick={() => setActiveTab("energyHistory")}>
                                        <Nav.Link eventKey="energyHistory">Energy History</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setActiveTab("claimHistory")}>
                                        <Nav.Link eventKey="claimHistory">Claim History</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setActiveTab("gameHistory")}>
                                        <Nav.Link eventKey="gameHistory">Game History</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setActiveTab("logs")}>
                                        <Nav.Link eventKey="logs">Logs</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="energyHistory">
                                        {activeTab === "energyHistory" && <EnergyHitory userDetails={userDetails} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="claimHistory">
                                        {activeTab === "claimHistory" && <ClaimHistory userDetails={userDetails} />}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="gameHistory">
                                        {activeTab === "gameHistory" && <GameHistory userDetails={userDetails} />}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="logs">
                                        {activeTab === "logs" && <Logs userDetails={userDetails} />}

                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>

                </div>
            </section >
        </>
    );
};

export default UserView;
