import axios from "axios";
// import { toast } from "react-toastify"

export const liveApi = process.env.REACT_APP_API_BASE_URL;
export const stagingApi = process.env.REACT_APP_API_STAGING_BASE_URL;

const currentUrl = window.location.href;

export const API_BASE_URL =
  currentUrl.includes("staging") || currentUrl.includes("localhost")
    ? stagingApi
    : liveApi;
const API_URL = API_BASE_URL + "/api";
export const API_VERSION = "/v1";

const axiosApi = axios.create({ baseURL: API_URL + API_VERSION });
axiosApi.defaults.withCredentials = true;
axiosApi.defaults.withXSRFToken = true;
export const updateToken = (token) => {
  //  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token
};
export const updateCSRFToken = (token) => {
  axiosApi.defaults.headers.common["csrf-token"] = token;
};

export const updateCookieInHeader = (cookie) => {
  axiosApi.defaults.headers.common["cookie"] = cookie;
};

let obj = null;
// if (typeof window !== "undefined") {
//   obj = localStorage.getItem("authToken")
// }
console.log("obj", obj);
// updateToken(obj ? obj : null)

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const checkStatusCode = (code, err) => {
  if (code && [403, 501, 502, 503].includes(code)) {
    if (axiosApi.defaults.headers.common["token"] !== undefined) {
      // LogoutUser();
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.response.data.message);
    }
  } else {
    throw new Error(err.message);
  }
};

export async function get(url, config = {}) {
  delete axiosApi.defaults.headers.common["x-nonce"];
  delete axiosApi.defaults.headers.common["x-signature"];

  // // const { signature, nonce } = await generateSignature();
  if (!config?.nonce || !config?.signature) {
    throw new Error("Signature missing!");
  }
  // if (config?.nonce && config?.signature) {
  //   axiosApi.defaults.headers.common["x-nonce"] = config?.nonce;
  //   axiosApi.defaults.headers.common["x-signature"] = config?.signature;
  // }

  let headers = {};
  if (config?.headers) {
    headers = { ...config.headers }
  }
  headers["x-nonce"] = config?.nonce;
  headers["x-signature"] = config?.signature;

  return await axiosApi
    .get(url, { ...config, withCredentials: true, withXSRFToken: true, headers: headers })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response?.data?.message || "Something went wrong!");

      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function post(url, data, config = {}) {
  delete axiosApi.defaults.headers.common["x-nonce"];
  delete axiosApi.defaults.headers.common["x-signature"];

  // // const { signature, nonce } = await generateSignature();
  if (!config?.nonce || !config?.signature) {
    throw new Error("Signature missing!");
  }
  // if (config?.nonce && config?.signature) {
  //   axiosApi.defaults.headers.common["x-nonce"] = config?.nonce;
  //   axiosApi.defaults.headers.common["x-signature"] = config?.signature;
  // }

  let headers = {};
  if (config?.headers) {
    headers = { ...config.headers }
  }
  headers["x-nonce"] = config?.nonce;
  headers["x-signature"] = config?.signature;


  return await axiosApi
    .post(url, data, { ...config, withCredentials: true, withXSRFToken: true, headers: headers })
    .then((response) => {
      if (response?.data?.status === "success") {
        return response?.data;
      } else {
        throw new Error(response?.data?.message || "Something went wrong!");
      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function put(url, data, config = {}) {
  delete axiosApi.defaults.headers.common["x-nonce"];
  delete axiosApi.defaults.headers.common["x-signature"];

  // // const { signature, nonce } = await generateSignature();
  if (!config?.nonce || !config?.signature) {
    throw new Error("Signature missing!");
  }
  // if (config?.nonce && config?.signature) {
  //   axiosApi.defaults.headers.common["x-nonce"] = config?.nonce;
  //   axiosApi.defaults.headers.common["x-signature"] = config?.signature;
  // }

  let headers = {};
  if (config?.headers) {
    headers = { ...config.headers }
  }
  headers["x-nonce"] = config?.nonce;
  headers["x-signature"] = config?.signature;

  return axiosApi
    .put(
      url,
      { ...data },
      { ...config, withCredentials: true, withXSRFToken: true, headers: headers }
    )
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response?.data?.message || "Something went wrong!");

      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}

export async function del(url, config = {}) {
  delete axiosApi.defaults.headers.common["x-nonce"];
  delete axiosApi.defaults.headers.common["x-signature"];

  // // const { signature, nonce } = await generateSignature();
  if (!config?.nonce || !config?.signature) {
    throw new Error("Signature missing!!");
  }
  // if (config?.nonce && config?.signature) {
  //   axiosApi.defaults.headers.common["x-nonce"] = config?.nonce;
  //   axiosApi.defaults.headers.common["x-signature"] = config?.signature;
  // }

  let headers = {};
  if (config?.headers) {
    headers = { ...config.headers }
  }
  headers["x-nonce"] = config?.nonce;
  headers["x-signature"] = config?.signature;


  return await axiosApi
    .delete(url, { ...config, withCredentials: true, withXSRFToken: true, headers: headers })
    .then((response) => {
      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(response?.data?.message || "Something went wrong!");

      }
    })
    .catch((e) => {
      checkStatusCode(!e.response ? null : e.response.status, e);
    });
}
