import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import * as Path from "routes/Path/index";
import { exportGameList, gameHistoryListApi } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import FormikForm from "components/shared/FormikForm";
import moment from "moment";
import GameHistoryModal from "components/Modals/Gamehistory/GameHistoryModal";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
  {
    name: "datepicker",
    type: "datepicker",
    label: "",
    placeholder: "",
  },
];
const initialValuesForFilter = {
  search: "",
};

const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gameHistoryList, loading, totalGameHistory } = useSelector(
    ({ Game }) => Game
  );
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [showPicker, setShowPicker] = useState(false);
  const [gameModalShow, setGameModalShow] = useState(false);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    status: "All",
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleEditHandler }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(gameHistoryListApi({ data: query }));
    } catch (error) { }
  }

  const handleEditHandler = (info) => {
    setGameModalShow(true);
    setId(info?._id);
    // navigate(`/admin/game-history/view/${info?._id}`);
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      status: info.status,
    });
  };

  const onClickExport = () => {
    dispatch(exportGameList({}));
  };
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    setShowPicker(false);
  };


  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      {gameModalShow &&
        <GameHistoryModal
          show={gameModalShow}
          handleClose={() => {
            setGameModalShow(false);
            setId(null);
          }}
          title={"Game Details"}
          id={id}
        />
      }
      <section className="section">
        <div className="section-header">
          <h1>Game History</h1>

          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Game History</div>
          </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Listing</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => onClickExport()}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                    dateRange={filter}
                    showPicker={showPicker}
                    setShowPicker={setShowPicker}
                    handleSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          {gameHistoryList?.length > 0 && (
            <Table
              data={gameHistoryList}
              columns={columns}
              setFilter={setFilter}
              total={totalGameHistory}
              filter={filter}
            />
          )}
          {!loading && gameHistoryList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Record Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
