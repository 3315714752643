import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LoadingButton from "components/shared/LoadingButton";
import FormikForm from "components/shared/FormikForm";
import Validation from "views/tasks/Validation";

const InputFields = [
    {
        name: "title",
        type: "text",
        label: "Title",
        placeholder: "Title...",
    },
    {
        name: "imageUrl",
        type: "image",
        label: "Icon",
        placeholder: "Icon...",
    },
    {
        name: "link",
        type: "text",
        label: "Link",
        placeholder: "Link...",
    },
    {
        name: "coins",
        type: "number",
        label: "Coins",
        placeholder: "Coins...",
    },
    {
        name: "type",
        type: "select",
        label: "Type",
        placeholder: "Type...",
        options: [{
            label: "Instant",
            value: "instant"
        },
        {
            label: "Friend",
            value: "friend"
        },
        {
            label: "Telegram",
            value: "telegram"
        }, {
            label: "Bitanica",
            value: "bitanica"
        }
        ]
    },
    {
        name: "upperButtonText",
        type: "text",
        label: "Upper Button Text",
        placeholder: "Enter...",
    },
    {
        name: "buttonText",
        type: "text",
        label: "Button Text",
        placeholder: "Enter...",
    },

    {
        name: "awardType",
        type: "select",
        label: "Award Type",
        placeholder: "Select...",
        options: [{
            label: "Coins",
            value: "coins"
        },
        {
            label: "Other",
            value: "other"
        }
        ]
    },
    {
        name: "count",
        type: "number",
        label: "Friends Count",
        placeholder: "Friends count...",
    },
    {
        name: "awardName",
        type: "text",
        label: "Award Name",
        placeholder: "Award name...",
    },
    {
        name: "awardIcon",
        type: "image",
        label: "Award Icon",
        placeholder: "Award icon...",
    },
    {
        name: "awardQuantity",
        type: "number",
        label: "Award Quantity",
        placeholder: "Award quantity...",
    },
    {
        name: "status",
        type: "select",
        label: "Status",
        placeholder: "Select...",
        options: [{
            label: "Active",
            value: "active"
        },
        {
            label: "Inactive",
            value: "inactive"
        }
        ]
    }
];

// awardData: { 
//     name: { type: String, default: null },
//     icon: { type: String, default: null },
//     quantity: { type: Number, default: 0 },
// }

export default function AddUpdateTask({
    show,
    handleClose,
    handleConfirm,
    title,
    loading,
    rowData,

}) {
    const [initialValues, setInitalValues] = useState({
        title: "",
        icon: "",
        link: "",
        type: "instant",
        coins: 0,
        imageUrl: "",
        count: 0,
        status: "",
        // taskID: "",
        // order: "",
        upperButtonText: "",
        buttonText: "",
        awardType: "coins",
        awardName: "",
        awardIcon: "",
        awardQuantity: 0
    });
    const [inputFields, setInputFields] = useState(InputFields);
    const formRef = React.useRef(null);

    const instantFields = ["title", "imageUrl", "link", "type", "status", "upperButtonText", "buttonText", "awardType", "coins", "awardName", "awardIcon", "awardQuantity"];
    const friendFields = ["title", "imageUrl", "type", "status", "count", "upperButtonText", "buttonText", "awardType", "coins", "awardName", "awardIcon", "awardQuantity"];
    const telegramFields = ["title", "imageUrl", "type", "status", "upperButtonText", "buttonText", "awardType", "coins", "awardName", "awardIcon", "awardQuantity"];
    const awardFields = ["awardName", "awardIcon", "awardQuantity"];
    const binaticaFields = ["title", "imageUrl", "link", "type", "status", "upperButtonText", "buttonText", "awardType", "coins", "awardName", "awardIcon", "awardQuantity"];

    useEffect(() => {
        if (rowData) {
            setInitalValues({
                title: rowData?.title || "",
                icon: rowData?.icon || "",
                link: rowData?.link || "",
                type: rowData?.type || "",
                coins: rowData?.coins || 0,
                imageUrl: rowData?.icon || "",
                count: rowData?.count || 0,
                status: rowData?.status || "",
                // taskID: rowData?.taskID || "",
                // order: rowData?.order || "",
                upperButtonText: rowData?.upperButtonText || "",
                buttonText: rowData?.buttonText || "",
                awardType: rowData?.awardType || "",
                awardName: rowData?.awardData?.name || "",
                awardIcon: rowData?.awardData?.icon || "",
                awardQuantity: rowData?.awardData?.quantity || 0
            });
        }
    }, [rowData])

    useEffect(() => {
        // Use formikValues to filter inputFields based on selected type
        const { type, awardType } = initialValues;
        let inputs = [];
        console.log("type", initialValues)
        if (type === "friend") {
            inputs = (InputFields.filter((item) => friendFields.includes(item.name)));
        } else if (type === "telegram") {
            inputs = (InputFields.filter((item) => telegramFields.includes(item.name)));
        } else if (type === "bitanica") {
            inputs = (InputFields.filter((item) => binaticaFields.includes(item.name)));
        } else {
            inputs = (InputFields.filter((item) => instantFields.includes(item.name)));
        }

        if (awardType === "coins") {
            inputs = inputs.filter((item) => !awardFields.includes(item?.name))
        } else {
            inputs = inputs.filter((item) => !["coins"].includes(item?.name))
        }

        setInputFields(inputs);

    }, [initialValues.type, initialValues.awardType]);


    const handleValuesChange = (newValues) => {
        console.log("newVal", newValues)
        setInitalValues(newValues); // Update the local state with new Formik values
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Header className="py-3 text-dark" closeButton>
                <Modal.Title className="fw-bold text-dark">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={handleConfirm}
                    loading={loading}
                    inputFields={inputFields}
                    buttonText={"Submit"}
                    col={6}
                    ref={formRef}
                    onValuesChange={handleValuesChange}
                />
            </Modal.Body>
        </Modal>
    );
}