import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";


const Columns = ({ deleteHandler, handleEdit }) => [
  {
    dataField: "quarter",
    text: "Quarter",
    formatter: (_, row) => (
      <span className="text-capitalize">{row.quarter}</span>
    ),
  },
  {
    dataField: "title",
    text: "Title",
    formatter: (_, row) => (
      <span className="text-capitalize">{row?.title || "--"}</span>
    ),
  },

  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {row.status}
      </Badge>
    ),
  },
  {
    text: "Created At",
    dataField: "date_created_utc",
    formatter: (_, row) => row.date_created_utc && DATE_FORMAT(row.date_created_utc),
  },
  {
    text: "Action",
    // dataField: "Inactive",
    formatter: (_, row) => (
      <>
        <button
          className="fas fa-pen"
          title="Edit"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          onClick={() => handleEdit(row)}
        ></button>
        {
          <button
            className="fas fa-trash-alt"
            style={{
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => deleteHandler(row._id)}
          ></button>
        }
      </>
    ),
  },
];
export default Columns;
