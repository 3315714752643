import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { Row, Col, FormGroup, Label, Input, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./instructions";
import { Alert, Button } from "react-bootstrap";
import { get } from "helpers/api_helper";
import { GET_STATISTICS_COUNT, GET_STATISTICS_EXCELL_FILE, GET_STATISTICS_GRAPH } from "helpers/url_helper";


const Statistics = () => {


    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const res1 = await get(`${GET_STATISTICS_GRAPH}/?month=11&year=2024`)
        const res2 = await get(`${GET_STATISTICS_COUNT}`);
        const res3 = await get(`${GET_STATISTICS_EXCELL_FILE}/?startDate=2024-11-25&endDate=2024-11-30`)
        console.log("resp1", res1);
        console.log("resp2", res2);
        console.log("resp3", res3);

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} lg={12}>
                            <Card>
                                <CardBody className="spinner-content">
                                    <Col lg={8}>
                                        <Instructions />
                                    </Col>

                                    <Col lg={8}>

                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container >
            </div >

        </React.Fragment >
    );
};

export default Statistics;