import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";


const initialState = {
  gameHistoryList: [],
  totalGameHistory: 0,
  singleGameHistory: null,
  loading: false,
  claimHistoryList: [],
  totalClaimHistory: 0,
  gameLeaderboardList: [],
  totalLeaderboard: 0,
  energyHistoryList: [],
  totalEnergyHistory: 0,
  loadingCoins: false,
  coinsList: [],
  exportLoading: false,
};

const slice = createSlice({
  name: "game",
  initialState: { ...initialState },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(Act.gameHistoryListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.gameHistoryListApi.fulfilled, (state, action) => {
      state.gameHistoryList = action.payload.data;
      state.totalGameHistory = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.gameHistoryListApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.gameHistoryDetailsApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.gameHistoryDetailsApi.fulfilled, (state, action) => {
      console.log("action.payload", action.payload)
      state.singleGameHistory = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Act.gameHistoryDetailsApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.gameLeaderboardListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.gameLeaderboardListApi.fulfilled, (state, action) => {
      state.gameLeaderboardList = action.payload.data;
      state.totalLeaderboard = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.gameLeaderboardListApi.rejected, (state, action) => {
      state.loading = false;

    });
    builder.addCase(Act.ClaimHistoryListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.ClaimHistoryListApi.fulfilled, (state, action) => {
      state.claimHistoryList = action.payload.data;
      state.totalClaimHistory = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.ClaimHistoryListApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.EnergyHistoryListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.EnergyHistoryListApi.fulfilled, (state, action) => {
      state.energyHistoryList = action.payload.data;
      state.totalEnergyHistory = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.EnergyHistoryListApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.getCoinsListApi.pending, (state, action) => {
      state.loadingCoins = true;
    });
    builder.addCase(Act.getCoinsListApi.fulfilled, (state, action) => {
      state.coinsList = action.payload.data;
      state.loadingCoins = false;
    });
    builder.addCase(Act.getCoinsListApi.rejected, (state, action) => {
      state.loadingCoins = false;
    });

    builder.addCase(Act.exportGameList.pending, (state, action) => {
      state.exportLoading = true;
    });
    builder.addCase(Act.exportGameList.fulfilled, (state, action) => {
      state.exportLoading = false;
    });
    builder.addCase(Act.exportGameList.rejected, (state, action) => {
      state.exportLoading = false;
    });

    builder.addCase(Act.exportClaimList.pending, (state, action) => {
      state.exportLoading = true;
    });
    builder.addCase(Act.exportClaimList.fulfilled, (state, action) => {
      state.exportLoading = false;
    });
    builder.addCase(Act.exportClaimList.rejected, (state, action) => {
      state.exportLoading = false;
    });

  },
});

export default slice;
