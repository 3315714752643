import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme file
import moment from "moment";
const DateRangeInput = ({
  dateRange = null,
  showPicker = false,
  setShowPicker = () => {},
  handleSelect = () => {},
}) => {
  const pickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log(dateRange, "dassssteRange");

  return (
    <div className="position-relative z-10">
      <input
        type="text"
        value={`${
          dateRange?.startDate || moment(Date.now()).format("YYYY-MM-DD")
        } - ${dateRange?.endDate || moment(Date.now()).format("YYYY-MM-DD")}`}
        readOnly
        onClick={() => setShowPicker((prev) => !prev)}
        className="form-control border p-2 w-72 cursor-pointer"
      />

      {/* Date Range Picker */}
      {showPicker && (
        <div className="position-absolute mt-2" style={{ right: 0, zIndex: 9 }}>
          <DateRangePicker
            ranges={[
              {
                startDate: dateRange?.startDate
                  ? new Date(dateRange?.startDate)
                  : new Date(),
                endDate: dateRange?.endDate
                  ? new Date(dateRange?.endDate)
                  : new Date(),
                key: "selection",
              },
            ]}
            onChange={handleSelect}
            locale={enUS}
            disabledDates={[]}
            // moveRangeOnFirstSelection={false}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeInput;
