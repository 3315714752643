import CryptoJS from 'crypto-js';
import { socket } from "utils/socket";


export async function encrypt(text, keyHex, ivHex) {
    try {
        const key = CryptoJS.enc.Hex.parse(keyHex);  // Parse the key from hex
        const iv = CryptoJS.enc.Hex.parse(ivHex);    // Parse the IV from hex

        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);  // Return encrypted string in hex format
    }
    catch (err) {
        console.log(err, "err");
    }
}

export async function decrypt(encryptedText, keyHex, ivHex) {
    try {
        const key = CryptoJS.enc.Hex.parse(keyHex);  // Parse the key from hex
        const iv = CryptoJS.enc.Hex.parse(ivHex);    // Parse the IV from hex

        const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedText);
        const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

        const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);  // Return decrypted string in utf8
    }
    catch (err) {
        console.log(err, "err");
    }
}



export function getValuesSocketData() {
    return new Promise((resolve, reject) => {
        try {
            socket.emit('getValue', { data: {} }, (data) => {
                if (data) {
                    resolve({
                        signature: data?.signature,
                        nonce: data?.nonce,
                    });
                } else {
                    reject({
                        message: "Signature not found!"
                    });
                }
            });
        } catch (err) {
            reject({
                message: "Signature not found!"
            });
        }

    });
}