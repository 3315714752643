import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Path from "routes/Path/index";
import {
  ClaimHistoryListApi,
  exportClaimList,
  gameLeaderboardListApi,
} from "store/actions";
import Table from "components/Table";
import Columns from "./Columns";
import FormikForm from "components/shared/FormikForm";
import moment from "moment";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
  {
    name: "datepicker",
    type: "datepicker",
    label: "",
    placeholder: "",
  },
  {
    name: "coinSymbol",
    type: "select",
    label: "",
    placeholder: "Select type",
    options: [
      { value: "All", label: "All" },
      { value: "BTNC", label: "BTNC" },
      { value: "", label: "CRYPTO" },
    ],
  },
  {
    name: "status",
    type: "select",
    label: "",
    placeholder: "Select status",
    options: [
      { value: "", label: "All" },
      { value: "available", label: "Available" },
      { value: "processing", label: "Pending" },
      { value: "claimed", label: "Claimed" },
      { value: "rejected", label: "Rejected" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  coinSymbol: "",
  status: ""
};

const List = () => {
  const dispatch = useDispatch();
  const { claimHistoryList, loading, totalClaimHistory } = useSelector(
    ({ Game }) => Game
  );
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [showPicker, setShowPicker] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    status: "",
    coinSymbol: "",
    startDate: null,
    endDate: null,
    status: ""
  });

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleEditHandler }));
  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(ClaimHistoryListApi({ data: query }));
    } catch (error) { }
  }

  const handleEditHandler = (info) => { };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      coinSymbol: info.coinSymbol,
      status: info.status
    });
  };

  const onClickExport = () => {
    dispatch(exportClaimList({}));
  };
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    setShowPicker(false);
  };
  return (
    <>
      <Helmet title={"Claim History"} />

      <section className="section">
        <div className="section-header">
          <h1>Claim History</h1>

          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Claim History</div>
          </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Listing</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => onClickExport()}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                    dateRange={filter}
                    showPicker={showPicker}
                    setShowPicker={setShowPicker}
                    handleSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          {claimHistoryList?.length > 0 && (
            <Table
              data={claimHistoryList}
              columns={columns}
              setFilter={setFilter}
              total={totalClaimHistory}
              filter={filter}
            />
          )}
          {!loading && claimHistoryList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
